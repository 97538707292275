var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    [
      _c(
        "el-header",
        {
          staticClass: "shadow-border-bottom",
          style: { backgroundColor: _vm.currentTheme.barBackgroundColor },
        },
        [
          _c(
            "div",
            {
              staticClass: "logo-container",
              style: {
                color: _vm.currentTheme.logoTextColor,
                backgroundColor: _vm.currentTheme.logoBackgroundColor,
                width: _vm.collapse ? "79px" : "285px",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "boreder-right",
                  style: { color: _vm.currentTheme.logoTextColor },
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.collapse = !_vm.collapse
                    },
                  },
                },
                [
                  _c("em", {
                    staticClass: "iconfont",
                    class: _vm.collapse ? "icon-indent" : "icon-outdent",
                    staticStyle: { "font-size": "30px" },
                  }),
                ]
              ),
              _c("transition", { attrs: { name: "el-fade-in" } }, [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.collapse,
                      expression: "!collapse",
                    },
                  ],
                  attrs: { src: require("@/assets/images/logo.png"), alt: "" },
                  on: { click: _vm.goHomePage },
                }),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "top-container" }, [
            _c("div", { staticClass: "left" }, [
              _vm.companyOptions.length
                ? _c(
                    "div",
                    { staticClass: "top-block" },
                    [
                      _c("el-cascader", {
                        staticClass: "switch-company",
                        style: { color: _vm.currentTheme.barTextColor },
                        attrs: {
                          placeholder: _vm.$t("selectCompanyTeam"),
                          options: _vm.companyOptions,
                        },
                        on: { change: _vm.handleCompanyTeamChange },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function ({ data }) {
                                return [
                                  _c("em", {
                                    class:
                                      data.type === "team"
                                        ? "el-icon-files"
                                        : "el-icon-suitcase",
                                    staticStyle: { "margin-right": "5px" },
                                  }),
                                  _c("span", [_vm._v(_vm._s(data.label))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3838652727
                        ),
                        model: {
                          value: _vm.currentCompanyTeam,
                          callback: function ($$v) {
                            _vm.currentCompanyTeam = $$v
                          },
                          expression: "currentCompanyTeam",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "right" }, [
              _c(
                "div",
                { staticClass: "top-block" },
                [
                  _c("personal-center-popover", {
                    attrs: { username: _vm.user.username || "admin" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-container",
        { staticClass: "container-body" },
        [
          _c(
            "el-aside",
            {
              class: { "is-collapse": _vm.collapse },
              style: { backgroundColor: _vm.currentTheme.menuBackgroundColor },
              attrs: { width: _vm.collapse ? "79px" : "250px" },
            },
            [
              _c(
                "div",
                { staticClass: "menu-container" },
                [
                  _c(
                    "el-menu",
                    {
                      ref: "elMenu",
                      attrs: {
                        "default-active": _vm.defaultActive,
                        "background-color":
                          _vm.currentTheme.menuBackgroundColor,
                        "text-color": _vm.currentTheme.menuTextColor,
                        "active-text-color": "#fff",
                        "unique-opened": true,
                        collapse: _vm.collapse,
                      },
                    },
                    [
                      _vm._l(_vm.leftMenulist, function (menu) {
                        return [
                          menu.children.length && menu.enable
                            ? _c(
                                "el-submenu",
                                { key: menu.name, attrs: { index: menu.id } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("em", { class: menu.icon }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t(menu.name))),
                                    ]),
                                  ]),
                                  _vm._l(menu.children, function (item) {
                                    return [
                                      item.enable
                                        ? _c(
                                            "el-menu-item",
                                            {
                                              key: item.id,
                                              attrs: {
                                                index: `/admin/${item.router}`,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toCurrentPage(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "item" },
                                                [
                                                  _c("em", {
                                                    class: item.icon,
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t(item.name))
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          !menu.children.length && menu.enable
                            ? _c(
                                "el-menu-item",
                                {
                                  key: menu.id,
                                  attrs: { index: `/admin/${menu.router}` },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toCurrentPage(menu)
                                    },
                                  },
                                },
                                [
                                  _c("em", { class: menu.icon }),
                                  _c(
                                    "span",
                                    { attrs: { slot: "title" }, slot: "title" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(menu.name)) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _c("el-main", [
            _c("div", { staticClass: "main" }, [
              _c(
                "div",
                { staticClass: "sub-main" },
                [_vm.isRouterAlive ? _c("router-view") : _vm._e()],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }